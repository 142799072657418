import React, { useEffect, useState, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { auth, db } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, getDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faHome, faUser, faComments } from '@fortawesome/free-solid-svg-icons';
import './Header.css';

const Header = () => {
  const [user, loading] = useAuthState(auth);
  const [showHeader, setShowHeader] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [profilePic, setProfilePic] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isProfessional, setIsProfessional] = useState(false);
  const [displayName, setDisplayName] = useState('Usuario');
  const location = useLocation();

  // Consolidamos las consultas a Firebase en un solo efecto para optimizar la carga.
  useEffect(() => {
    if (!user) {
      setProfilePic('');
      setDisplayName('Usuario');
      setIsAdmin(false);
      setIsProfessional(false);
      return;
    }

    const fetchData = async () => {
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const adminDocRef = doc(db, 'admin', user.uid);
        const professionalDocRef = doc(db, 'professionals', user.uid);

        // Ejecuta las tres peticiones en paralelo.
        const [userDoc, adminDoc, professionalDoc] = await Promise.all([
          getDoc(userDocRef),
          getDoc(adminDocRef),
          getDoc(professionalDocRef)
        ]);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setProfilePic(userData.profilePic || '');
          setDisplayName(userData.displayName || 'Usuario');
        }
        setIsAdmin(adminDoc.exists());

        if (professionalDoc.exists()) {
          setIsProfessional(true);
          const professionalData = professionalDoc.data();
          // Se prioriza el displayName del profesional si existe.
          setDisplayName(professionalData.displayName || 'Usuario');
        } else {
          setIsProfessional(false);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [user]);

  // Optimización del scroll: usamos requestAnimationFrame para throttlear las actualizaciones.
  useEffect(() => {
    let lastScroll = window.scrollY;
    let ticking = false;

    const handleScroll = () => {
      const currentScroll = window.scrollY;
      if (!ticking) {
        window.requestAnimationFrame(() => {
          setShowHeader(currentScroll < lastScroll);
          lastScroll = currentScroll;
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Usamos useCallback para evitar recrear estas funciones en cada render.
  const handleLogout = useCallback(() => {
    auth.signOut();
  }, []);

  const toggleMenu = useCallback(() => {
    setMenuOpen(prev => !prev);
  }, []);

  if (loading) {
    return <p>Cargando...</p>;
  }

  if (location.pathname === '/reservations-calendar') {
    return null;
  }

  return (
    <>
      <header className={`header-custom-header ${showHeader ? 'visible' : 'hidden'}`}>
        <nav className="header-custom-nav">
          <div className="header-logo-container">
            <Link to="/">
              <img 
                src="https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/Logo%2FMesa%20de%20trabajo%201%400.5x.png?alt=media&token=c1ba4c61-b6ac-4849-8c59-b040d136b9c4" 
                alt="Logo" 
                className="header-logo"
                loading="lazy"
              />
            </Link>
          </div>
          <ul className="header-custom-nav-list">
            <li className="header-custom-nav-item"><Link to="/">Home</Link></li>
            <li className="header-custom-nav-item"><Link to="/professionals">Profesionales</Link></li>
            {user ? (
              <>
                <li className="header-custom-nav-item">
                  <Link to={isProfessional ? `/professional-profile/${user.uid}` : "/my-account"}>
                    {isProfessional ? "Perfil Profesional" : "Mi Cuenta"}
                  </Link>
                </li>
                {isAdmin && (
                  <li className="header-custom-nav-item">
                    <Link to="/create-professional">Crear Profesional</Link>
                  </li>
                )}
                <li className="header-custom-nav-item">
                  <div className="header-profile-container" onClick={toggleMenu}>
                    <img 
                      src={profilePic || 'https://via.placeholder.com/40'} 
                      alt="Profile" 
                      className="header-profile-photo"
                      loading="lazy"
                    />
                    <span className="header-profile-name">{displayName}</span>
                    {menuOpen && (
                      <div className="header-profile-menu">
                        <button onClick={handleLogout}>Cerrar sesión</button>
                        <Link to="/professionals">Ver Profesionales</Link>
                        <Link to={isProfessional ? `/professional-profile/${user.uid}` : "/my-account"}>
                          {isProfessional ? "Perfil Profesional" : "Mi Cuenta"}
                        </Link>
                      </div>
                    )}
                  </div>
                </li>
              </>
            ) : (
              <>
                <li className="header-custom-nav-item"><Link to="/register">Registrarse</Link></li>
                <li className="header-custom-nav-item"><Link to="/login">Iniciar sesión</Link></li>
              </>
            )}
          </ul>
        </nav>
      </header>
      <nav className="header-mobile-nav">
        <ul className="header-mobile-nav-list">
          <li className="header-mobile-nav-item">
            <Link to="/"><FontAwesomeIcon icon={faHome} /><span></span></Link>
          </li>
          <li className="header-mobile-nav-item">
            <Link to="/professionals"><FontAwesomeIcon icon={faCalendarAlt} /><span></span></Link>
          </li>
          <li className="header-mobile-nav-item">
            <Link to={isProfessional ? `/professional-profile/${user.uid}` : "/my-account"}>
              <FontAwesomeIcon icon={faUser} /><span></span>
            </Link>
          </li>
          <li className="header-mobile-nav-item">
            <Link to={isProfessional ? "/chat/professionalChat/ProfessionalChatPage" : "/chat/userChatMobile/UserChatPageMobile"}>
              <FontAwesomeIcon icon={faComments} /><span></span>
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Header;
