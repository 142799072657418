import React, { useEffect, useState, useRef } from 'react';
import './HeaderSection.css';

const HeaderSection = ({ words, currentWordIndex, handleViewProfessionals, showInstallButton, handleInstallClick }) => {
  const [videoUrls, setVideoUrls] = useState([
    'https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/videos-portada%2F4109221-hd_1080_2048_25fps.mp4?alt=media&token=21144dd9-7a39-4da1-887e-c9698afd5f19',
    'https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/videos-portada%2F4109309-hd_1366_720_50fps.mp4?alt=media&token=2f780020-0cec-41b4-b2d5-87e6e38d3798',
    'https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/videos-portada%2F4109491-hd_1080_2048_25fps.mp4?alt=media&token=27d53f75-feaf-4f36-8f4f-df0b7bcefe21'
  ]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (videoUrls.length > 1) {
      intervalRef.current = setInterval(() => {
        setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoUrls.length);
      }, 8000); // Cambia de video cada 8 segundos
    }

    return () => clearInterval(intervalRef.current);
  }, [videoUrls]);

  const handleVideoChange = (index) => {
    setCurrentVideoIndex(index);
    clearInterval(intervalRef.current); // Reiniciar el temporizador si el usuario cambia manualmente
  };

  return (
    <div id="header-container" className="portada-reseñas-container">
      <div className="portada-reseñas-titulo">
        <h1>
          Encuentra tu profesional de{' '}
          <span className="portada-reseñas-palabra-cambio">{words[currentWordIndex]}</span>
        </h1>
        <button onClick={handleViewProfessionals} className="portada-reseñas-boton">
          Ver Profesionales
        </button>
        {showInstallButton && (
          <button onClick={handleInstallClick} className="install-button">
            Instalar App
          </button>
        )}
      </div>

      <div className="portada-reseñas-lista">
        {videoUrls.length > 0 && (
          <video
            className="portada-video fade-in"
            src={videoUrls[currentVideoIndex]}
            autoPlay
            loop
            muted
            playsInline
            disablePictureInPicture
            controlsList="nodownload noremoteplayback"
            ref={videoRef}
            preload="metadata"
            onError={() => console.error('Error al cargar el video')}
          />
        )}

        {/* Controles de navegación */}
        <div className="video-navigation">
          {videoUrls.map((_, index) => (
            <button
              key={index}
              className={`nav-button ${index === currentVideoIndex ? 'active' : ''}`}
              onClick={() => handleVideoChange(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HeaderSection;