import React from 'react';
import './ServicesSection.css';

const services = [
  {
    image: 'https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/Portadas%2F00006-3189076852.png?alt=media&token=d9f9c249-de04-41f1-8624-4e593469cacc',
    title: 'Servicio 1',
    description: 'Descripción del servicio 1',
    link: 'https://example.com/service1',
    icon: 'https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/Portadas%2Ficonos%2Fquality_3500896.png?alt=media&token=0b532735-e9fa-4408-8aff-a45dc92ff828' // Añade el enlace del ícono aquí
  },
  {
    image: 'https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/Portadas%2F00006-3189076852.png?alt=media&token=d9f9c249-de04-41f1-8624-4e593469cacc',
    title: 'Servicio 2',
    description: 'Descripción del servicio 2',
    link: 'https://example.com/service2',
    icon: 'https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/Portadas%2Ficonos%2Fquality_3500896.png?alt=media&token=0b532735-e9fa-4408-8aff-a45dc92ff828' // Añade el enlace del ícono aquí
  },
  {
    image: 'https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/Portadas%2F00006-3189076852.png?alt=media&token=d9f9c249-de04-41f1-8624-4e593469cacc',
    title: 'Servicio 3',
    description: 'Descripción del servicio 3',
    link: 'https://example.com/service3',
    icon: 'https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/Portadas%2Ficonos%2Fquality_3500896.png?alt=media&token=0b532735-e9fa-4408-8aff-a45dc92ff828' // Añade el enlace del ícono aquí
  },
];

const ServicesSection = () => {
  return (
    <div id="services-container" className="container">
      <div className="services-cards">
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <a href={service.link} className="service-card-link">
              <div className="service-image" style={{ backgroundImage: `url(${service.image})` }}>
                <img src={service.icon} alt="icon" className="service-icon" />
              </div>
              <div className="service-overlay">
                <h2 className="service-card-title">{service.title}</h2>
                <p className="service-description">{service.description}</p>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesSection;