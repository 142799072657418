// src/Professionals.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getProfessionals } from './firebase';
import StarRating from './StarRating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faRedo, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import Loader from './carga/Loader';
import './Professionals.css';
import './StarRating.css';

const defaultProfilePic = 'https://via.placeholder.com/100';

const Professionals = () => {
  const [professionals, setProfessionals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedBio, setExpandedBio] = useState({});

  useEffect(() => {
    const fetchProfessionals = async () => {
      const professionalsList = await getProfessionals();
      setTimeout(() => {
        setProfessionals(professionalsList);
        setLoading(false);
      }, 2000);
    };
  
    fetchProfessionals();
  }, []);

  const toggleBio = (id) => {
    setExpandedBio((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="professionals-container">
      <h2>Profesionales</h2>
      {professionals.length === 0 ? (
        <p>No hay profesionales disponibles.</p>
      ) : (
        <div className="professionals-list">
          {professionals.map((professional) => (
            <Link to={`/professional-detail/${professional.id}`} key={professional.id} className="professional-card-link">
              <div className="professional-card">
                <div className="professional-image-container">
                  <img src={professional.imageUrl || defaultProfilePic} alt={professional.name} className="professional-image" />
                </div>
                <div className="professional-info">
                  <h2 className="professional-name">{professional.name}</h2>
                  <div className="professional-rating">
                    <StarRating rating={professional.averageRating} />
                    <span className="rating-score">{professional.averageRating ? professional.averageRating.toFixed(1) : 'Sin valoraciones'}</span>
                  </div>
                  <p className="professional-description">{professional.description}</p>
                  <p className="professional-bio">
                    {expandedBio[professional.id] || (professional.bio && professional.bio.length <= 100)
                      ? professional.bio
                      : `${professional.bio ? professional.bio.substring(0, 200) : ''}...`}
                    {professional.bio && professional.bio.length > 100 && (
                      <button onClick={(e) => { e.preventDefault(); toggleBio(professional.id); }} className="view-more-button">
                        {expandedBio[professional.id] ? 'Ver menos' : 'Ver más'}
                      </button>
                    )}
                  </p>
                  <p className="professional-price">Precio por hora: {professional.hourlyRate ? `${professional.hourlyRate}€` : 'No especificado'}</p>
                  <div className="professional-min-reservation">
                    <FontAwesomeIcon icon={faMoneyBillWave} className="money-icon" />
                    <span>Reserva mínima: {professional.minReservation ? `${professional.minReservation}€` : '30€'}</span>
                  </div>
                  <div className="professional-calendar">
                    <FontAwesomeIcon icon={faCalendarAlt} className="calendar-icon" />
                    <span>Agenda actualizada</span>
                  </div>
                  <div className="professional-repeat">
                    <FontAwesomeIcon icon={faRedo} className="repeat-icon" />
                    <span>{professional.repeatClients} veces contratado</span>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Professionals;