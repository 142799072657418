import React, { useState } from 'react';
import './Locations.css';

const Locations = () => {
  const [expandedCity, setExpandedCity] = useState(null);

  const toggleExpand = (city) => {
    setExpandedCity((prevCity) => (prevCity === city ? null : city));
  };

  const categories = {
    madrid: ['Limpieza'],
    barcelona: ['Próximamente'],
    bilbao: ['Próximamente'],
  };

  return (
    <div className="locations-container">
      <h2 className="locations-title">Lugares donde trabajamos</h2>
      <div className="locations-grid">
        {Object.keys(categories).map((city) => (
          <div key={city} className="location-card" onClick={() => toggleExpand(city)}>
            <h4 className={expandedCity === city ? 'bold' : ''}>
              {city.charAt(0).toUpperCase() + city.slice(1)}
              <span>{expandedCity === city ? '-' : '+'}</span>
            </h4>
            {expandedCity === city && (
              <div className="categories">
                {categories[city].map((category) => (
                  <div key={category} className="category-item">
                    {category}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Locations;