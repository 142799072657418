import React from 'react';
import './MarqueePanel.css';

const MarqueePanel = () => {
  return (
    <div className="marquee-container">
      <div className="marquee">
        <img src="https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/wenowsocial-images%2Fscore.png?alt=media&token=8745c76a-9558-4ecd-8c20-bc791958f700" alt="Logo 1" /> {/* Reemplaza con la ruta real del logo */}
        <img src="https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/wenowsocial-images%2Fscore.png?alt=media&token=8745c76a-9558-4ecd-8c20-bc791958f700" alt="Logo 2" /> {/* Reemplaza con la ruta real del logo */}
        <img src="https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/wenowsocial-images%2Fscore.png?alt=media&token=8745c76a-9558-4ecd-8c20-bc791958f700" alt="Logo 3" /> {/* Reemplaza con la ruta real del logo */}
        <img src="https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/wenowsocial-images%2Fscore.png?alt=media&token=8745c76a-9558-4ecd-8c20-bc791958f700" alt="Logo 4" /> {/* Reemplaza con la ruta real del logo */}
        <img src="https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/wenowsocial-images%2Fscore.png?alt=media&token=8745c76a-9558-4ecd-8c20-bc791958f700" alt="Logo 5" /> {/* Reemplaza con la ruta real del logo */}
        {/* Agrega más logos según sea necesario */}
      </div>
    </div>
  );
};

export default MarqueePanel;