import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import './DisplayName.css';  // Ruta del archivo CSS actualizada

const DisplayName = ({ user, displayName, setDisplayName }) => {
  const handleNameChange = (e) => {
    setDisplayName(e.target.value);
  };

  const handleNameSubmit = async (e) => {
    e.preventDefault();
    if (user) {
      try {
        const userDocRef = doc(db, 'users', user.uid);
        await updateDoc(userDocRef, { displayName });
        console.log('Nombre actualizado:', displayName);
      } catch (error) {
        console.error('Error updating name:', error);
      }
    }
  };

  return (
    <form onSubmit={handleNameSubmit} className="displaynamemyaccount">
      <input
        type="text"
        value={displayName}
        onChange={handleNameChange}
        className="name-input"
        placeholder="Nombre"
      />
      <button type="submit" className="submit-btn">Actualizar Nombre</button>
    </form>
  );
};

export default DisplayName;
