import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import ProfilePicture from './ProfilePicture';
import DisplayName from './DisplayName';
import PersonalData from './PersonalData';
import './EditProfile.css';

const EditProfile = () => {
  const [user, loading] = useAuthState(auth);
  const [profilePic, setProfilePic] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [personalData, setPersonalData] = useState({
    address: '',
    phoneNumber: ''
  });

  useEffect(() => {
    const fetchProfileData = async () => {
      if (user) {
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setProfilePic(userDoc.data().profilePic || '');
            setDisplayName(userDoc.data().displayName || '');
            setPersonalData({
              address: userDoc.data().address || '',
              phoneNumber: userDoc.data().phoneNumber || ''
            });
            console.log('Profile Data:', userDoc.data());
          } else {
            console.log('Documento de usuario no existe. Creando uno nuevo.');
            await setDoc(userDocRef, { profilePic: '', displayName: '', address: '', phoneNumber: '' }, { merge: true });
          }
        } catch (error) {
          console.error('Error fetching profile data:', error);
        }
      }
    };

    fetchProfileData();
  }, [user]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="edit-profile">
      <h1>Editar Perfil</h1>
      <div className="display-name-container">
        <DisplayName user={user} displayName={displayName} setDisplayName={setDisplayName} />
      </div>
      <div className="personal-data-container">
        <PersonalData user={user} personalData={personalData} setPersonalData={setPersonalData} />
      </div>
    </div>
  );
};

export default EditProfile;