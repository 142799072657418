import React, { useState, useEffect } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { doc, setDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import './ProfilePicture.css';

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

const ProfilePicture = ({ profilePic, setProfilePic }) => {
  const [user, loading] = useAuthState(auth);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (user) {
      console.log('User ID:', user.uid);
    }
  }, [user]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    if (!selectedFile.type.startsWith("image/")) {
      alert("Solo se permiten imágenes.");
      return;
    }

    if (selectedFile.size > MAX_FILE_SIZE) {
      alert("El tamaño de la imagen no debe superar los 10MB.");
      return;
    }

    setFile(selectedFile);
    setPreview(URL.createObjectURL(selectedFile));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (file && user) {
      setLoadingUpload(true);
      setMessage('');
      try {
        const storage = getStorage();
        const storageRef = ref(storage, `profilePics/${user.uid}`);
        
        // Eliminar la foto de perfil anterior si existe
        if (profilePic) {
          try {
            const oldProfilePicRef = ref(storage, profilePic);
            await deleteObject(oldProfilePicRef);
            console.log('Old profile pic deleted:', profilePic);
          } catch (error) {
            if (error.code === 'storage/object-not-found') {
              console.log('Old profile pic does not exist.');
            } else {
              throw error;
            }
          }
        }

        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        const userDocRef = doc(db, 'users', user.uid);
        await setDoc(userDocRef, { profilePic: downloadURL }, { merge: true });
        setProfilePic(downloadURL);
        setMessage('Foto de perfil actualizada con éxito.');
        console.log('Profile Pic Updated:', downloadURL);
      } catch (error) {
        console.error('Error updating profile pic:', error);
        setMessage('Error al actualizar la foto de perfil.');
      } finally {
        setLoadingUpload(false);
        setFile(null);
        setPreview(null);
      }
    }
  };

  const handleDelete = async () => {
    if (user && profilePic) {
      setLoadingUpload(true);
      setMessage('');
      try {
        const storage = getStorage();
        const profilePicRef = ref(storage, profilePic);
        await deleteObject(profilePicRef);
        const userDocRef = doc(db, 'users', user.uid);
        await setDoc(userDocRef, { profilePic: '' }, { merge: true });
        setProfilePic('');
        setMessage('Foto de perfil eliminada con éxito.');
        console.log('Profile Pic Deleted');
      } catch (error) {
        console.error('Error deleting profile pic:', error);
        setMessage('Error al eliminar la foto de perfil.');
      } finally {
        setLoadingUpload(false);
      }
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="profile-picture-wrapper-myaccountprofile">
  <img src={preview || profilePic} alt="Profile" className="profile-picture-myaccountprofile" />
  
  <div className="profile-actions-myaccountprofile">
    <input type="file" onChange={handleFileChange} className="file-input-myaccountprofile" />
    <button type="submit" className="submit-btn-myaccountprofile" disabled={loadingUpload}>
      {loadingUpload ? "Subiendo..." : "Actualizar Foto de Perfil"}
    </button>
    {profilePic && (
      <button type="button" className="delete-btn-myaccountprofile" onClick={handleDelete} disabled={loadingUpload}>
        <FontAwesomeIcon icon={faTrash} /> Eliminar Foto de Perfil
      </button>
    )}
    <Link to="/edit-profile" className="edit-profile-link-myaccountprofile">
      <FontAwesomeIcon icon={faEdit} className="edit-icon-myaccountprofile" />
    </Link>
    {message && <p className="message-myaccountprofile">{message}</p>}
  </div>
</div>

  );
};

export default ProfilePicture;