import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, db, getUserReservations } from './firebase';
import ProfilePicture from './myaccount/ProfilePicture';
import DisplayName from './myaccount/DisplayName';
import PersonalData from './myaccount/PersonalData';
import Reservations from './myaccount/Reservations';
import './MyAccount.css';
import Header from './Header';

const MyAccount = () => {
  const [user, loading] = useAuthState(auth);
  const [profilePic, setProfilePic] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [personalData, setPersonalData] = useState({
    address: '',
    phoneNumber: ''
  });
  const [reservations, setReservations] = useState([]);

  useEffect(() => {
    const fetchProfilePic = async () => {
      if (user) {
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setProfilePic(userDoc.data().profilePic || '');
            setDisplayName(userDoc.data().displayName || '');
            setPersonalData({
              address: userDoc.data().address || '',
              phoneNumber: userDoc.data().phoneNumber || ''
            });
            console.log('Profile Pic:', userDoc.data().profilePic);
          } else {
            console.log('Documento de usuario no existe. Creando uno nuevo.');
            await setDoc(userDocRef, { profilePic: '', displayName: '', address: '', phoneNumber: '' }, { merge: true });
          }
        } catch (error) {
          console.error('Error fetching profile pic:', error);
        }
      }
    };

    const fetchReservations = async () => {
      if (user) {
        try {
          const userReservations = await getUserReservations(user.uid);
          setReservations(userReservations);
          console.log('Reservations:', userReservations);
        } catch (error) {
          console.error('Error fetching reservations:', error);
        }
      }
    };

    fetchProfilePic();
    fetchReservations();
  }, [user]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="my-account-dashboard">
      <div className="dashboard-content">
        <div className="profile-section">
          <ProfilePicture user={user} profilePic={profilePic} setProfilePic={setProfilePic} />
          
        </div>
        <div className="personal-data-section">
          <DisplayName displayName={displayName} setDisplayName={setDisplayName} />
          <PersonalData personalData={personalData} setPersonalData={setPersonalData} />
        </div>
        <div className="reservations-section">
          <Reservations reservations={reservations} />
        </div>
      </div>
    </div>
  );
};

export default MyAccount;