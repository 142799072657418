import React from 'react';
import './Reservations.css';

const Reservations = ({ reservations }) => {
  return (
    <div className="reservationsmyaccount">
      <h2>Reservas</h2>
      {reservations.length === 0 ? (
        <p className="no-reservations">No tienes reservas.</p>
      ) : (
        <ul className="reservation-list">
          {reservations.map((reservation) => (
            <li key={reservation.id} className="reservation-item">
              <div className="reservation-info">
                <p><span className="reservation-label">Profesional:</span> {reservation.professionalName || 'Desconocido'}</p>
                <div className="reservation-divider"></div>
                <p><span className="reservation-label">Horas Contratadas:</span> {reservation.hours !== 'N/A' ? reservation.hours : 'N/A'}</p>
                <p><span className="reservation-label">Total:</span> {reservation.price !== 'N/A' ? `$${reservation.price}` : 'N/A'}</p>
                <p><span className="reservation-label">Fecha:</span> {reservation.date || 'N/A'}</p>
                <p><span className="reservation-label">Hora:</span> {reservation.time || 'N/A'}</p>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Reservations;
