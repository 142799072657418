import React from 'react';
import './HowItWorks.css';

const HowItWorks = () => {
  return (
    <div className="howitworks-container">
      <h2 className="howitworks-title">¿Cómo Funciona?</h2>
      <div className="howitworks-steps">
        <div className="step">
          <img src="https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/wenowsocial-images%2Fcontract.png?alt=media&token=78ca36c5-e420-4d59-ae24-fe27506bd3da" alt="Vector de personal" /> {/* Vector para el paso 1 */}
          <h3>1. Contrata al Personal</h3>
          <p>Elige entre profesionales verificados para cubrir tus necesidades.</p>
        </div>
        <div className="step">
          <img src="https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/wenowsocial-images%2Fskin-care.png?alt=media&token=d84b847c-dc01-4e7a-a918-0762abdaf33c" alt="Vector de productos" /> {/* Vector para el paso 2 */}
          <h3>2. Coloca los Productos</h3>
          <p>Decide los productos y servicios que se ajusten a tu proyecto.</p>
        </div>
        <div className="step">
          <img src="https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/wenowsocial-images%2Fscore.png?alt=media&token=8745c76a-9558-4ecd-8c20-bc791958f700" alt="Vector de servicio satisfactorio" /> {/* Vector para el paso 3 */}
          <h3>3. Servicio Satisfactorio y Económico</h3>
          <p>Disfruta de un servicio de alta calidad a precios asequibles.</p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;