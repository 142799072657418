// src/ProfessionalReservationForm.js
import React, { useState, useEffect, useRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { auth, db, addReservation } from './firebase';
import { doc, getDoc, collection, addDoc, onSnapshot } from 'firebase/firestore';
import './ProfessionalReservationForm.css';

const stripePromise = loadStripe('pk_test_51QMfPHLiYDAQqOb6Iev10Ft88a0IjDh4OWQ7bWlJGEgZMNVrP6z4VJbWY9JqJPCE5Z85su2GOpxgMpxM3HSrk7qA00vZU19Zws');

/* LÓGICA DE DRAG SCROLL:
   Para habilitar el arrastre horizontal en el contenedor, sin flechas */
const useDragScroll = () => {
  const containerRef = useRef(null);
  const isDown = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const handleMouseDown = (e) => {
    isDown.current = true;
    containerRef.current.classList.add('dragging');
    // La posición del cursor dentro del contenedor
    startX.current = e.pageX - containerRef.current.offsetLeft;
    // Posición actual del scroll
    scrollLeft.current = containerRef.current.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDown.current = false;
    containerRef.current.classList.remove('dragging');
  };

  const handleMouseUp = () => {
    isDown.current = false;
    containerRef.current.classList.remove('dragging');
  };

  const handleMouseMove = (e) => {
    if (!isDown.current) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX.current) * 1; // Ajusta el factor si deseas más/menos velocidad
    containerRef.current.scrollLeft = scrollLeft.current - walk;
  };

  return { containerRef, handleMouseDown, handleMouseLeave, handleMouseUp, handleMouseMove };
};

// Selector horizontal de fechas (próximos 30 días), sin flechas y con drag scroll
const HorizontalDatePicker = ({ selectedDate, onChange }) => {
  const { 
    containerRef, 
    handleMouseDown, 
    handleMouseLeave, 
    handleMouseUp, 
    handleMouseMove 
  } = useDragScroll();

  const startDate = new Date();
  const dates = [];
  for (let i = 0; i < 30; i++) {
    let d = new Date(startDate);
    d.setDate(startDate.getDate() + i);
    dates.push(d);
  }

  return (
    <div className="horizontal-date-picker">
      {/* Contenedor que se arrastra con el mouse */}
      <div
        className="date-items"
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        {dates.map(date => (
          <div 
            key={date.toISOString()}
            className={`date-item ${selectedDate && date.toDateString() === selectedDate.toDateString() ? 'selected' : ''}`}
            onClick={() => onChange(date)}
          >
            <div className="day-name">
              {date.toLocaleDateString('es-ES', { weekday: 'short' })}
            </div>
            <div className="day-number">
              {date.getDate()}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Selector horizontal de horas (08:00 a 20:00, intervalos de 30 minutos), con drag scroll
const HorizontalTimePicker = ({ selectedTime, onChange }) => {
  const { 
    containerRef, 
    handleMouseDown, 
    handleMouseLeave, 
    handleMouseUp, 
    handleMouseMove 
  } = useDragScroll();

  const times = [];
  const startHour = 8;
  const endHour = 20;
  for (let hour = startHour; hour <= endHour; hour++) {
    times.push(`${hour.toString().padStart(2, '0')}:00`);
    if (hour !== endHour) {
      times.push(`${hour.toString().padStart(2, '0')}:30`);
    }
  }

  return (
    <div className="horizontal-time-picker">
      <div
        className="time-items"
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        {times.map(timeStr => (
          <div
            key={timeStr}
            className={`time-item ${selectedTime === timeStr ? 'selected' : ''}`}
            onClick={() => onChange(timeStr)}
          >
            {timeStr}
          </div>
        ))}
      </div>
    </div>
  );
};

const ProfessionalReservationForm = ({ professionalId }) => {
  const [date, setDate] = useState(null);
  const [time, setTime] = useState('');
  const [duration, setDuration] = useState(1);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchProfessional = async () => {
      try {
        if (professionalId) {
          const professionalRef = doc(db, "professionals", professionalId);
          const professionalDoc = await getDoc(professionalRef);
          if (professionalDoc.exists()) {
            setSelectedProfessional({ id: professionalId, ...professionalDoc.data() });
          } else {
            setError('Profesional no encontrado.');
          }
        }
      } catch (error) {
        setError('Error al obtener la información del profesional.');
      }
    };
    fetchProfessional();
  }, [professionalId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    const user = auth.currentUser;
    if (!user) {
      setError('Debes iniciar sesión para hacer una reserva.');
      setLoading(false);
      return;
    }

    if (!selectedProfessional) {
      setError('Por favor, selecciona un profesional.');
      setLoading(false);
      return;
    }

    if (!date || !time || duration <= 0) {
      setError('Por favor, completa todos los campos correctamente.');
      setLoading(false);
      return;
    }

    try {
      const professionalData = selectedProfessional;
      const hourlyRate = professionalData.hourlyRate;

      if (!hourlyRate || isNaN(hourlyRate)) {
        setError('El precio por hora no es válido.');
        setLoading(false);
        return;
      }

      const amountToCharge = hourlyRate * duration * 100; // Stripe usa centavos

      // Crear la sesión de pago en Firestore
      const docRef = await addDoc(collection(db, 'customers', user.uid, 'checkout_sessions'), {
        mode: 'payment',
        line_items: [{
          price_data: {
            currency: 'usd',
            product_data: {
              name: `Reserva con ${professionalData.name}`,
            },
            unit_amount: hourlyRate * 100,
          },
          quantity: duration,
        }],
        success_url: `${window.location.origin}/success`,
        cancel_url: `${window.location.origin}/cancel`,
        client_reference_id: user.uid,
      });

      // Guardar datos en localStorage
      localStorage.setItem('sessionId', docRef.id);
      localStorage.setItem('reservationData', JSON.stringify({
        professionalId,
        date,
        time,
        duration,
        description,
        amount: amountToCharge / 100,
      }));

      // Escuchar cambios en la sesión de pago
      onSnapshot(docRef, async (snap) => {
        if (!snap.exists()) {
          setError('No se pudo encontrar la sesión de pago.');
          setLoading(false);
          return;
        }

        const data = snap.data();
        if (!data) {
          setError('No se pudo obtener la información de la sesión de pago.');
          setLoading(false);
          return;
        }

        const { error, url, payment_status } = data;

        if (error) {
          console.error('Error en la sesión de pago:', error);
          alert(`Error en la sesión de pago: ${error.message}`);
          setLoading(false);
          return;
        }

        if (url) {
          // Redirigir a Stripe Checkout
          window.location.assign(url);
        }

        if (payment_status === 'paid') {
          console.log('Pago completado, agregando la reserva...');
          const reservationData = {
            professionalId,
            date,
            time,
            duration,
            description,
            amount: amountToCharge / 100,
            userId: user.uid,
            createdAt: new Date(),
          };

          try {
            await addReservation(user.uid, professionalId, reservationData);
            console.log('Reserva agregada exitosamente a Firestore.');
            alert('¡Reserva realizada con éxito!');
          } catch (error) {
            console.error('Error al agregar la reserva:', error);
            alert('Error al agregar la reserva. Revisa la consola para más detalles.');
          }
          setLoading(false);
        }
      });

    } catch (error) {
      console.error('Error al crear la sesión de pago:', error);
      setError('Error al crear la sesión de pago. Inténtalo de nuevo.');
      setLoading(false);
    }
  };

  const calculateTotal = () => {
    if (selectedProfessional && duration > 0) {
      return selectedProfessional.hourlyRate * duration;
    }
    return 0;
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {error && <p className="error-message">{error}</p>}
        {selectedProfessional && (
          <div className="professional-info">
            <p>Nombre: {selectedProfessional.name}</p>
            <p>Precio por hora: ${selectedProfessional.hourlyRate}</p>
          </div>
        )}

        {/* Selector horizontal de fechas (drag scroll) */}
        <HorizontalDatePicker selectedDate={date} onChange={setDate} />

        {/* Selector horizontal de horas (drag scroll) */}
        <HorizontalTimePicker selectedTime={time} onChange={setTime} />

        <input 
          type="number" 
          min="1" 
          value={duration} 
          onChange={(e) => setDuration(e.target.value)} 
          required 
          placeholder="Duración en horas"
        />
        <textarea 
          value={description} 
          onChange={(e) => setDescription(e.target.value)} 
          required 
          placeholder="Descripción de la reserva"
        />
        
        <div className="total">
          <p>Total a pagar: ${calculateTotal()}</p>
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Procesando...' : 'Reservar'}
        </button>
      </form>
    </div>
  );
};

const WrappedProfessionalReservationForm = (props) => (
  <Elements stripe={stripePromise}>
    <ProfessionalReservationForm {...props} />
  </Elements>
);

export default WrappedProfessionalReservationForm;
