import React from 'react';
import { addOrUpdatePersonalData } from './addOrUpdatePersonalData';
import './PersonalData.css';  // Ruta del archivo CSS actualizada

/**
 * Componente para manejar los datos personales del usuario.
 * @param {Object} props - Propiedades del componente.
 * @param {Object} props.user - Información del usuario.
 * @param {Object} props.personalData - Datos personales actuales del usuario.
 * @param {Function} props.setPersonalData - Función para actualizar los datos personales.
 */
const PersonalData = ({ user, personalData, setPersonalData }) => {
  // Maneja los cambios en los campos de entrada del formulario
  const handlePersonalDataChange = (e) => {
    const { name, value } = e.target;
    setPersonalData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  // Maneja el envío del formulario para actualizar los datos personales
  const handlePersonalDataSubmit = async (e) => {
    e.preventDefault();
    if (user) {
      try {
        await addOrUpdatePersonalData(personalData);
        console.log('Datos personales actualizados:', personalData);
      } catch (error) {
        console.error('Error al actualizar los datos personales:', error);
      }
    }
  };

  return (
    <form onSubmit={handlePersonalDataSubmit} className="personaldatamyaccount">
      <input
        type="text"
        name="address"
        value={personalData.address || ''}
        onChange={handlePersonalDataChange}
        className="personal-data-input"
        placeholder="Dirección"
        required
      />
      <input
        type="text"
        name="phoneNumber"
        value={personalData.phoneNumber || ''}
        onChange={handlePersonalDataChange}
        className="personal-data-input"
        placeholder="Número de Teléfono"
        required
      />
      <button type="submit" className="submit-btn">
        Actualizar Datos Personales
      </button>
    </form>
  );
};

export default PersonalData;
