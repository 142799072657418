import React from 'react';
import './Loader.css';

const Loader = () => {
  return (
    <div className="loader-charlotmed-container">
      <div className="loader-charlotmed-circle">
        <svg viewBox="25 25 50 50">
          <circle cx="50" cy="50" r="20" />
        </svg>
      </div>
      <div className="loader-charlotmed-info">
        <div className="loader-charlotmed-line-large" />
        <div className="loader-charlotmed-line-extra" />
      </div>
    </div>
  );
};

export default Loader;